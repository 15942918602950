import React from "react";
import Index from "pages/index";
import Page404 from "pages/page404";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {createGlobalStyle} from "styled-components";
import ReferralHandler from "./components/ReferralHandler";

const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
    }

    .ant-carousel .slick-dots li button {
        background: #000;
        opacity: 0.4;
    }

    .ant-carousel .slick-dots li.slick-active button {
        opacity: 1;
        background: #000;
    }
`;

export default () => (
    <Router>
        <ReferralHandler/>
        <GlobalStyles/>
        <Switch>
            <Route exact path='/' component={Index}/>
            <Route exact path='/index' component={Index}/>
            <Route component={Page404}/>
        </Switch>
    </Router>
);
