import React from "react";
import {useMediaQuery} from 'react-responsive';
import {Carousel, Image} from "antd";

const contentStyle = {
    color: '#030342',
    width: '100%',
    lineHeight: '160px',
    textAlign: 'center',
};

const strategy_images = [
    "https://cdzv.com/strategy_img/Monosnap_2024-10-22_21-11-49.png",
    "https://cdzv.com/strategy_img/Monosnap_2024-10-22_21-14-28.png",
    "https://cdzv.com/strategy_img/Monosnap_2024-10-22_21-15-49.png",
    "https://cdzv.com/strategy_img/Monosnap_2024-10-22_21-17-34.png",
    'https://cdzv.com/strategy_img/Monosnap_2024-10-22_21-18-57.png',
    "https://cdzv.com/strategy_img/Monosnap_2024-10-22_21-37-13.png",
    "https://cdzv.com/strategy_img/Monosnap_2024-10-22_21-38-57.png",
    "https://cdzv.com/strategy_img/Monosnap_2024-10-22_21-41-34.png",
    "https://cdzv.com/strategy_img/Monosnap_2024-10-22_21-25-10.png",
    "https://cdzv.com/strategy_img/Monosnap_2024-10-22_21-26-51.png",
    "https://cdzv.com/strategy_img/Monosnap_2024-10-22_21-28-20.png",
    "https://cdzv.com/strategy_img/Monosnap_2024-10-22_21-35-00.png",
    "https://cdzv.com/strategy_img/Monosnap_2024-10-22_21-43-06.png",
    "https://cdzv.com/strategy_img/Monosnap_2024-10-22_21-45-29.png",
    "https://cdzv.com/strategy_img/Monosnap_2024-10-22_21-56-26.png",
    "https://cdzv.com/strategy_img/Monosnap_2024-10-22_21-57-51.png",
    "https://cdzv.com/strategy_img/Monosnap_2024-10-22_21-59-09.png"
];

const width = {
    mobile: "340px",
    mobile_hr: "480px",
    tablet: "600px",
    tablet_hr: "400px",
    desktop: "650px",
}

function MStrategyCarousel() {

    const isMobile = useMediaQuery({maxWidth: 450});
    const isMobileHR = useMediaQuery({maxWidth: 767});
    const isTablet = useMediaQuery({minWidth: 768, maxWidth: 819});
    const isTabletHR = useMediaQuery({minWidth: 820, maxWidth: 1280});
    const isDesktop = useMediaQuery({minWidth: 1281});

    return (
        <>
            <Carousel
                fade={true}
                autoplay
                dotPosition="bottom"
                style={{
                    borderRadius: 15,
                    backgroundColor: '#f3f3f3',
                    display: "block",
                    maxWidth: "100%",
                    height: "90%",
                    width: isMobile ? width.mobile : isMobileHR ? width.mobile_hr : isTablet ? width.tablet : isTabletHR ? width.tablet_hr : width.desktop
                }}
            >
                {strategy_images.map((url, index) => (
                    <div key={index}>
                        <h3 style={contentStyle}>
                            <Image src={url} width={'95%'} preview={false}/>
                        </h3>
                    </div>
                ))}
            </Carousel>
        </>
    );
}

export default MStrategyCarousel;