import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"background": "linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/CDZV%20Members%20Area%202024-06-15%2017-52-08.png?v=2024-06-15T15:52:31.116Z) center/cover",
	"padding": "60px 0",
	"sm-padding": "40px 0",
	"color": "--light",
	"font": "--base",
	"quarkly-title": "CopyTrades",
	"height": "650px",
	"mix-blend-mode": "luminosity"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"margin": "-16px -16px -16px -16px",
			"display": "flex",
			"justify-content": "center"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"padding": "16px 16px 16px 16px",
			"width": "75%",
			"md-width": "100%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"color": "--light",
			"letter-spacing": "1px",
			"text-transform": "uppercase",
			"margin": "0",
			"children": "Immediate earning opportunities"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "h1",
			"font": "--headline1",
			"md-font": "--headline2",
			"margin": "10px 0",
			"text-align": "center",
			"lg-font": "normal 900 60px/1.2 --fontFamily-sans",
			"color": "#f5fff5",
			"text-shadow": "4px 4px 0 --color-veles",
			"padding": "100px 0px 0px 0px",
			"children": "All strategies are ready to trade the moment you subscribe"
		}
	}
};

const CdzvCopyTrades = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Text {...override("text")} />
					<Text {...override("text1")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(CdzvCopyTrades, { ...Section,
	defaultProps,
	overrides
});
export default CdzvCopyTrades;