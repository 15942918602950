import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Section } from "@quarkly/widgets";
import QuarklycommunityKitYouTube from "./QuarklycommunityKitYouTube";
const defaultProps = {
	"padding": "0px 0 0px 0",
	"quarkly-title": "CdzvScam",
	"background": "--color-light",
	"display": "flex"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 50px 0px",
			"font": "normal 600 46px/1.2 --fontFamily-sans",
			"text-align": "center",
			"md-margin": "0px 0px 50px 0px",
			"children": "Test Before You Invest"
		}
	},
	"quarklycommunityKitYouTube": {
		"kind": "QuarklycommunityKitYouTube",
		"props": {
			"url": "https://www.youtube.com/watch?v=VUz7X0ZHlxI",
			"modestbranding": true,
			"showOverlay": true
		}
	}
};

const CdzvScam = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" />
		<Text {...override("text")} />
		<QuarklycommunityKitYouTube {...override("quarklycommunityKitYouTube")} />
		{children}
	</Section>;
};

Object.assign(CdzvScam, { ...Section,
	defaultProps,
	overrides
});
export default CdzvScam;