import React from "react";
import theme from "theme";
import { Theme, Image, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>Code Zero Visual Trading | Build, Test and Automate trading strategies without programming.</title>
			<meta name="keywords"
				  content="Code Zero Visual Trading, CDZV, TradingView, indicator, strategy, backtesting, algo-trading, trading, forex, stock, crypto, cryptocurrency, profit, algorithmic trading, automated trading, trading tools, trading platform, no-code trading, visual trading, trading automation, create strategy, build strategy, test strategy, automate strategy, webhooks"/>
			<meta name="description"
				  content="Code Zero Visual Trading (CDZV) fot TradingVieew. Build, test and automate your trading strategy in minutes without programming. Quickly evaluate your ideas and turn them into profitable solutions with our tools. Build strategies in minutes without coding skills."/>
			<meta property={"og:description"} content={"Code Zero Visual Trading (CDZV) fot TradingVieew. Build, test and automate your trading strategy in minutes without programming. Quickly evaluate your ideas and turn them into profitable solutions with our tools. Build strategies in minutes without coding skills."} />
			<meta name="author" content="Code Zero Visual Trading"/>
			<link rel="canonical" href="https://cdzv.com/"/>
			<link rel="shortcut icon" href="favicon.ico"/>
		</Helmet>
		<Section padding="88px 0 88px 0" min-height="100vh" quarkly-title="404-6">
			<Override slot="SectionContent" max-width="1220px" justify-content="center" />
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Image margin="0px 0px 7px 0px" src="https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/warning-error-delete%201.png?v=2021-08-31T00:16:56.957Z" srcSet="https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/warning-error-delete%201.png?v=2021-08-31T00%3A16%3A56.957Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/warning-error-delete%201.png?v=2021-08-31T00%3A16%3A56.957Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/warning-error-delete%201.png?v=2021-08-31T00%3A16%3A56.957Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/warning-error-delete%201.png?v=2021-08-31T00%3A16%3A56.957Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/warning-error-delete%201.png?v=2021-08-31T00%3A16%3A56.957Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/warning-error-delete%201.png?v=2021-08-31T00%3A16%3A56.957Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/612695d67f2b1f001fa06c1f/images/warning-error-delete%201.png?v=2021-08-31T00%3A16%3A56.957Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw" />
				<Text color="--dark" margin="0px 0px 24px 0px" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif" font="--headline1">
					404 Error!
				</Text>
				<Text
					width="40%"
					lg-width="80%"
					font="--lead"
					color="--grey"
					margin="0px 0px 40px 0px"
					text-align="center"
				>
					The page you are looking for is not available or doesn’t belong to this website!
				</Text>
				<Link
					font="--lead"
					padding="12px 24px 12px 24px"
					border-radius="8px"
					href="https://cdzv.com"
					text-decoration-line="initial"
					color="--light"
					background="--color-veles"
				>
					Go Back to Home
				</Link>
			</Box>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"665dd05e1d712a0023346c52"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\nhtml {\nscroll-behavior: smooth;\n}\n"}
			</style>
		</RawHtml>
	</Theme>;
});