import {useEffect, useState} from 'react';

export const useReferralCode = () => {
    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    return {
        getReferralCode: () => getCookie('cdzv_referralCode')
    };
};

const ReferralHandler = () => {
    const [referralCode, setReferralCode] = useState(null);

    const setCookie = (name, value, days) => {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=${value};${expires};path=/`;
    };

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const referralCode = urlParams.get('r');
        const existingCode = getCookie('cdzv_referralCode');

        if (referralCode) {
            setCookie('cdzv_referralCode', referralCode, 30);
            setReferralCode(referralCode);

            urlParams.delete('r');
            const newUrl = `${window.location.pathname}${urlParams.toString() ? `?${urlParams.toString()}` : ''}`;
            window.history.replaceState({}, '', newUrl);
        } else if (existingCode) {
            setReferralCode(existingCode);
        }
    }, []);

    return null;
};

export default ReferralHandler;