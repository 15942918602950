import React from "react";
import {useOverrides, Override} from "@quarkly/components";
import {Image, Box, Button, Section} from "@quarkly/widgets";
import QuarklycommunityKitMenu from "./QuarklycommunityKitMenu";
import MobileSidePanelFix from "./MobileSidePanelFix";

const Logo = "https://cdzv.com/strategy_img/Image_2024-10-30_11-01-25.jpg";

const defaultProps = {
    "sm-padding": "8px 0 8px 0",
    "quarkly-title": "Header-3",
    "background": "--color-white",
    "flex-direction": "column-reverse",
    "align-items": "stretch",
    "box-shadow": "5px 5px 5px 0 rgba(111, 131, 159, 0.44)",
    "justify-content": "space-between",
    "padding": "0px 0 0 0",
    "position": "fixed",
    "flex-wrap": "no-wrap",
    "z-index": "999",
    "height": "65px",
    "margin": "0 0px 0 0px",
    "right": 0,
    "left": 0,
    "top": "0px",
    "bottom": "9211px"
};
const overrides = {
    "box": {
        "kind": "Box",
        "props": {
            "display": "flex",
            "padding": "12px 0",
            "justify-content": "space-between",
            "align-items": "flex-start",
            "flex-direction": "row",
            "width": "30%",
            "sm-width": "50%",
            "sm-align-items": "center",
            "sm-flex-direction": "row",
            "sm-justify-content": "flex-start",
            "md-width": "50%",
            "lg-width": "70%",
            "md-justify-content": "flex-start"
        }
    },
    "box1": {
        "kind": "Box",
        "props": {
            "min-width": "100px",
            "min-height": "100px",
            "padding": "0 0px 0px 0px"
        }
    },
    "image": {
        "kind": "Image",
        "props": {
            "src": Logo,
            "width": "20%",
            "box-shadow": "3px 3px 3px 0px --color-greyD2",
            "padding": "0px 0px 0px 0px",
            "margin": "0px 0px 0px 0px",
            "display": "block",
            "sm-margin": "50px 0px 0px 0px",
            "sm-width": "30%",
            "lg-width": "13%",
            "md-width": "25%"
        }
    },
    "mobileSidePanelFix": {
        "kind": "MobileSidePanelFix",
        "props": {
            "menuPosition": "right",
            "animDuration": "0.5s",
            "onloadShow": false,
            "breakpoint": "lg"
        }
    },
    "mobileSidePanelFixOverride": {
        "kind": "Override",
        "props": {
            "slot": "Button Text",
            "md-display": "none",
            "lg-display": "none",
            "children": "menu"
        }
    },
    "mobileSidePanelFixOverride1": {
        "kind": "Override",
        "props": {
            "slot": "Content",
            "align-self": "center",
            "display": "block",
            "padding": "10px 16px 24px 16px",
            "height": "60px"
        }
    },
    "box2": {
        "kind": "Box",
        "props": {
            "align-items": "center",
            "lg-justify-content": "center",
            "lg-align-items": "center",
            "justify-content": "flex-end",
            "display": "flex",
            "lg-flex-direction": "column",
            "sm-align-items": "center",
            "sm-min-width": "100px",
            "md-min-width": "100px",
            "md-align-items": "center",
            "lg-width": "90%",
            "lg-min-width": "100px",
            "lg-max-width": "1280px",
            "lg-display": "flex"
        }
    },
    "quarklycommunityKitMenu": {
        "kind": "QuarklycommunityKitMenu",
        "props": {}
    },
    "quarklycommunityKitMenuOverride": {
        "kind": "Override",
        "props": {
            "slot": "link-index",
            "text-decoration-line": "initial",
            "href": "/index#home",
            "hover-border-color": "--color-veles",
            "color": "--dark",
            "hover-text-decoration-line": "underline",
            "hover-color": "--veles",
            "children": "Home"
        }
    },
    "quarklycommunityKitMenu1": {
        "kind": "QuarklycommunityKitMenu",
        "props": {}
    },
    "quarklycommunityKitMenuOverride1": {
        "kind": "Override",
        "props": {
            "slot": "link-index",
            "text-decoration-line": "initial",
            "href": "/index#pricing",
            "hover-border-color": "--color-veles",
            "hover-color": "--veles",
            "hover-text-decoration-line": "underline",
            "active-text-decoration-line": "underline",
            "children": "Pricing"
        }
    },
    "quarklycommunityKitMenu2": {
        "kind": "QuarklycommunityKitMenu",
        "props": {}
    },
    "quarklycommunityKitMenuOverride2": {
        "kind": "Override",
        "props": {
            "slot": "link-index",
            "text-decoration-line": "initial",
            "href": "https://docs.cdzv.com/code-zero-visual-trading_en/user-guide/how-to-connect-copy-trading",
            "hover-border-color": "--color-veles",
            "hover-color": "--veles",
            "hover-text-decoration-line": "underline",
            "active-text-decoration-line": "underline",
            "target": "_blank",
            "children": "Copy trading"
        }
    },
    "quarklycommunityKitMenu3": {
        "kind": "QuarklycommunityKitMenu",
        "props": {}
    },
    "quarklycommunityKitMenuOverride3": {
        "kind": "Override",
        "props": {
            "slot": "link-index",
            "text-decoration-line": "initial",
            "href": "/index#faq",
            "hover-color": "--veles",
            "hover-text-decoration-line": "underline",
            "children": "F.A.Q."
        }
    },
    "quarklycommunityKitMenu4": {
        "kind": "QuarklycommunityKitMenu",
        "props": {}
    },
    "quarklycommunityKitMenuOverride4": {
        "kind": "Override",
        "props": {
            "slot": "link-index",
            "text-decoration-line": "initial",
            "target": "_blank",
            "hover-color": "--veles",
            "font": "20",
            "hover-font": "16px --fontFamily-sans",
            "hover-text-decoration-line": "underline",
            "href": "https://docs.cdzv.com/code-zero-visual-trading_en",
            "children": "Manual"
        }
    },
    "quarklycommunityKitMenuOverride5": {
        "kind": "Override",
        "props": {
            "slot": "link",
            "href": "https://docs.cdzv.com/",
            "target": "_blank"
        }
    },
    "quarklycommunityKitMenuOverride6": {
        "kind": "Override",
        "props": {
            "slot": "link-active"
        }
    },
    "quarklycommunityKitMenuOverride7": {
        "kind": "Override",
        "props": {
            "slot": "link-404"
        }
    },
    "button": {
        "kind": "Button",
        "props": {
            "background": "--color-veles",
            "border-radius": "8px",
            "href": "https://panel.cdzv.com",
            "target": "_blank",
            "type": "link",
            "text-decoration-line": "initial",
            "disabled": false,
            "hover-background": "--color-indigo",
            "margin": "0px 15px 0px 0px",
            "lg-margin": "0px 0px 10px 0px",
            // "display": "none"
        }
    },
    "button1": {
        "kind": "Button",
        "props": {
            "background": "--color-veles",
            "border-radius": "8px",
            "href": "https://panel.cdzv.com",
            "target": "_blank",
            "type": "link",
            "text-decoration-line": "initial",
            "disabled": false,
            "hover-background": "--color-indigo",
            "margin": "0px 1px 0px 0px",
            "display": "none"
        }
    }
};

const CdzvMenu = props => {
    const {
        override,
        children,
        rest
    } = useOverrides(props, overrides, defaultProps);
    return <Section {...rest}>
        <Override slot="SectionContent" flex-direction="row" justify-content="space-between"
                  margin="0px 20px 0px 20px"/>
        <Box {...override("box")}>
            <Box {...override("box1")}>
                <Image {...override("image")} />
            </Box>
        </Box>
        <MobileSidePanelFix {...override("mobileSidePanelFix")}>
            <Override {...override("mobileSidePanelFixOverride")} />
            <Override {...override("mobileSidePanelFixOverride1")} />
            <Box {...override("box2")}>
                <QuarklycommunityKitMenu {...override("quarklycommunityKitMenu")}>
                    <Override {...override("quarklycommunityKitMenuOverride")} />
                </QuarklycommunityKitMenu>
                <QuarklycommunityKitMenu {...override("quarklycommunityKitMenu1")}>
                    <Override {...override("quarklycommunityKitMenuOverride1")} />
                </QuarklycommunityKitMenu>
                {/*<QuarklycommunityKitMenu {...override("quarklycommunityKitMenu2")}>*/}
                {/*	<Override {...override("quarklycommunityKitMenuOverride2")} />*/}
                {/*</QuarklycommunityKitMenu>*/}
                <QuarklycommunityKitMenu {...override("quarklycommunityKitMenu3")}>
                    <Override {...override("quarklycommunityKitMenuOverride3")} />
                </QuarklycommunityKitMenu>
                <QuarklycommunityKitMenu {...override("quarklycommunityKitMenu4")}>
                    <Override {...override("quarklycommunityKitMenuOverride4")} />
                    <Override {...override("quarklycommunityKitMenuOverride5")} />
                    <Override {...override("quarklycommunityKitMenuOverride6")} />
                    <Override {...override("quarklycommunityKitMenuOverride7")} />
                </QuarklycommunityKitMenu>
                <Button {...override("button")}>
                    Log in
                </Button>
                <Button {...override("button1")}>
                    Demo
                </Button>
            </Box>
        </MobileSidePanelFix>
        {children}
    </Section>;
};

Object.assign(CdzvMenu, {
    ...Section,
    defaultProps,
    overrides
});
export default CdzvMenu;