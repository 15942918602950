export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"665dd05e1d712a0023346c59",
				"665dd05e1d712a0023346c5c"
			]
		},
		"665dd05e1d712a0023346c59": {
			"id": "665dd05e1d712a0023346c59",
			"name": "404",
			"pageUrl": "404"
		},
		"665dd05e1d712a0023346c5c": {
			"id": "665dd05e1d712a0023346c5c",
			"name": "index",
			"pageUrl": "index",
			"styles": {}
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {
			"display": "block"
		},
		"seo": {
			"favicon_32px": "https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/favicon.ico?v=2024-06-03T16:01:40.686Z",
			"customSitemapDomain": "https://cdzv.com",
			"og:image": "https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/favicon.ico?v=2024-06-03T16:01:40.686Z",
			"title": "",
			"description": "Code Zero Visual Trading (CDZV) fot TradingVieew. Build, test and automate your trading strategy in minutes without programming. Quickly evaluate your ideas and turn them into profitable solutions with our tools. Build strategies in minutes without coding skills.",
			"og:description": "Code Zero Visual Trading (CDZV) fot TradingVieew. Build, test and automate your trading strategy in minutes without programming. Quickly evaluate your ideas and turn them into profitable solutions with our tools. Build strategies in minutes without coding skills."
		}
	}
}