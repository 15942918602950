import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, LinkBox, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "0px 0 30px 0",
	"background": "--color-light"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"margin": "40px 0px 0px 0px",
			"display": "flex",
			"flex-direction": "row",
			"flex-wrap": "wrap",
			"align-content": "stretch",
			"align-items": "center",
			"justify-content": "space-between"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"position": "relative",
			"display": "block",
			"flex-direction": "column",
			"margin": "0px 0px 0px 15px",
			"lg-margin": "0px 0px 0px 0px",
			"sm-width": "calc(100% / 2)",
			"sm-margin": "0px 0px 0px 0px",
			"width": "calc(100% / 6)",
			"md-width": "calc(100% / 3)",
			"mix-blend-mode": "normal"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"href": "https://accounts.binance.com/en/register?ref=OSHDFY9J",
			"target": "_blank"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/Binance_logo.svg?v=2024-06-03T18:41:12.318Z",
			"border-radius": "16px",
			"max-width": "100%",
			"title": "binance",
			"alt": "binance"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"position": "relative",
			"display": "flex",
			"flex-direction": "column",
			"margin": "0px 0px 0px 20px",
			"lg-margin": "0px 0px 0px 0px",
			"sm-width": "calc(100% / 2)",
			"sm-margin": "0px 0px 0px 0px",
			"width": "8%",
			"md-width": "calc(100% / 3)"
		}
	},
	"linkBox1": {
		"kind": "LinkBox",
		"props": {
			"href": "https://www.bybit.com/uk-UA/invite/?ref=0NN1MN",
			"target": "_blank"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/BYBIT-Logo.png?v=2024-06-03T18:38:08.198Z",
			"border-radius": "16px",
			"max-width": "100%",
			"align-self": "auto",
			"order": "0",
			"display": "inline-block",
			"alt": "bybit",
			"title": "bybit"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"position": "relative",
			"display": "flex",
			"flex-direction": "column",
			"margin": "0px 0px 0px 20px",
			"lg-margin": "0px 0px 0px 0px",
			"sm-width": "calc(100% / 2)",
			"sm-margin": "0px 0px 0px 0px",
			"md-width": "calc(100% / 3)",
			"flex": "0 1 auto",
			"order": "0",
			"align-self": "center",
			"width": "10%"
		}
	},
	"linkBox2": {
		"kind": "LinkBox",
		"props": {
			"href": "https://www.htx.com/en-us/v/register/double-invite/web/?inviter_id=11345710&invite_code=rc2c5223",
			"target": "_blank"
		}
	},
	"image2": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/huobi.png?v=2024-06-03T18:42:22.752Z",
			"border-radius": "16px",
			"max-width": "100%",
			"alt": "huobi",
			"title": "huobi"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"position": "relative",
			"display": "flex",
			"flex-direction": "column",
			"margin": "0px 0px 0px 20px",
			"lg-margin": "0px 0px 0px 0px",
			"sm-width": "calc(100% / 2)",
			"sm-margin": "0px 0px 0px 0px",
			"width": "10%",
			"md-width": "calc(100% / 3)",
			"padding": "0px 0px 0px 0px"
		}
	},
	"linkBox3": {
		"kind": "LinkBox",
		"props": {
			"href": "https://www.kucoin.com/r/rf/QBAEVVMH",
			"target": "_blank"
		}
	},
	"image3": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/kucoin-logo-768x204.jpg?v=2024-06-03T18:43:38.085Z",
			"border-radius": "16px",
			"max-width": "100%",
			"alt": "kucoin",
			"title": "kucoin"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"position": "relative",
			"display": "flex",
			"flex-direction": "column",
			"margin": "0px 0px 0px 20px",
			"lg-margin": "0px 0px 0px 0px",
			"sm-width": "calc(100% / 2)",
			"sm-margin": "0px 0px 0px 0px",
			"width": "8%",
			"md-width": "calc(100% / 3)"
		}
	},
	"linkBox4": {
		"kind": "LinkBox",
		"props": {
			"href": "https://www.okx.com/ua/join/53775592",
			"target": "_blank"
		}
	},
	"image4": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/okx.svg?v=2024-06-03T18:38:08.193Z",
			"border-radius": "16px",
			"max-width": "100%",
			"alt": "okx",
			"title": "okx"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"position": "relative",
			"display": "flex",
			"flex-direction": "column",
			"margin": "0px 0px 0px 20px",
			"lg-margin": "0px 0px 0px 0px",
			"sm-width": "calc(100% / 2)",
			"sm-margin": "0px 0px 0px 0px",
			"width": "10%",
			"md-width": "calc(100% / 3)"
		}
	},
	"linkBox5": {
		"kind": "LinkBox",
		"props": {
			"href": "https://ibkr.com/referral/ievgenii739",
			"target": "_blank"
		}
	},
	"image5": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/ib-logo-stacked.png?v=2024-06-03T18:39:17.772Z",
			"border-radius": "16px",
			"max-width": "100%",
			"alt": "Interactive Brokers",
			"title": "Interactive Brokers"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"width": "10%",
			"display": "flex"
		}
	},
	"image6": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/665dd05e1d712a0023346c54/images/moe.png?v=2024-06-03T19:00:10.351Z",
			"display": "block",
			"alt": "more",
			"title": "more"
		}
	}
};

const CdzvEchanges = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			order="0"
			display="flex"
			align-items="center"
			align-content="center"
			flex-wrap="no-wrap"
			justify-content="center"
			text-align="center"
			flex="0 1 auto"
			align-self="auto"
		/>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<LinkBox {...override("linkBox")}>
					<Image {...override("image")} />
				</LinkBox>
			</Box>
			<Box {...override("box2")}>
				<LinkBox {...override("linkBox1")}>
					<Image {...override("image1")} />
				</LinkBox>
			</Box>
			<Box {...override("box3")}>
				<LinkBox {...override("linkBox2")}>
					<Image {...override("image2")} />
				</LinkBox>
			</Box>
			<Box {...override("box4")}>
				<LinkBox {...override("linkBox3")}>
					<Image {...override("image3")} />
				</LinkBox>
			</Box>
			<Box {...override("box5")}>
				<LinkBox {...override("linkBox4")}>
					<Image {...override("image4")} />
				</LinkBox>
			</Box>
			<Box {...override("box6")}>
				<LinkBox {...override("linkBox5")}>
					<Image {...override("image5")} />
				</LinkBox>
			</Box>
			<Box {...override("box7")}>
				<Image {...override("image6")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(CdzvEchanges, { ...Section,
	defaultProps,
	overrides
});
export default CdzvEchanges;