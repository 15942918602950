import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "60px 0",
	"sm-padding": "40px 0",
	"color": "--dark",
	"background": "--color-white",
	"id": "faq"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"margin": "-16px -16px -16px -16px",
			"display": "flex",
			"flex-wrap": "wrap"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"padding": "16px 16px 16px 16px",
			"width": "33.333%",
			"md-width": "100%"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"as": "h2",
			"font": "--headline1",
			"margin": "0 0 8px 0",
			"children": "FAQs"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--lead",
			"margin": "0",
			"color": "--greyD2",
			"children": "Need answers? We got’em"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"padding": "16px 16px 16px 16px",
			"width": "66.66%",
			"md-width": "100%"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "row",
			"flex-wrap": "wrap"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"width": "50%",
			"padding": "16px 16px 16px 16px",
			"sm-width": "100%"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"children": "Do I need programming skills to use this?"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": "No, Code Zero Visual Trading is designed to be fully accessible without coding. Create, test, and automate strategies visually!"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"width": "50%",
			"padding": "16px 16px 16px 16px",
			"sm-width": "100%"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"children": "What types of trading strategies are supported?"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": "Code Zero supports a wide range, including trend-following, grid, and range-bound (flat) strategies."
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"width": "50%",
			"padding": "16px 16px 16px 16px",
			"sm-width": "100%"
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"children": "Can I integrate custom indicators?"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": "Yes! Code Zero allows the use of both standard TradingView indicators and custom indicators created by the TradingView community."
		}
	},
	"box11": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column",
			"width": "50%",
			"padding": "16px 16px 16px 16px",
			"sm-width": "100%"
		}
	},
	"box12": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"as": "h3",
			"font": "--headline3",
			"margin": "12px 0",
			"children": "How do I set up alerts?"
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			"as": "p",
			"font": "--base",
			"margin": "12px 0",
			"color": "--greyD2",
			"children": "You can configure alerts to be sent via Telegram or any external system using webhook notifications."
		}
	}
};

const CdzvFaq = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Text {...override("text")} />
					<Text {...override("text1")} />
				</Box>
			</Box>
			<Box {...override("box3")}>
				<Box {...override("box4")}>
					<Box {...override("box5")}>
						<Box {...override("box6")}>
							<Text {...override("text2")} />
							<Text {...override("text3")} />
						</Box>
					</Box>
					<Box {...override("box7")}>
						<Box {...override("box8")}>
							<Text {...override("text4")} />
						</Box>
						<Text {...override("text5")} />
					</Box>
					<Box {...override("box9")}>
						<Box {...override("box10")}>
							<Text {...override("text6")} />
							<Text {...override("text7")} />
						</Box>
					</Box>
					<Box {...override("box11")}>
						<Box {...override("box12")}>
							<Text {...override("text8")} />
							<Text {...override("text9")} />
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(CdzvFaq, { ...Section,
	defaultProps,
	overrides
});
export default CdzvFaq;